<template>
  <div class="image-grid-wrapper">
    <div :class="gridClass">
      <b-img-lazy v-for="(image, index) in displayedImages" :key="index" :src="image" :alt="`Image ${index + 1}`" />
      <div v-if="remainingImagesCount > 0" class="more-overlay">+{{ remainingImagesCount }}</div>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      images: {
        type: Array,
        required: true,
      },
    },
    computed: {
      displayedImages() {
        // Display only the first 4 images
        return this.images.slice(0, 4);
      },
      remainingImagesCount() {
        // Calculate the remaining image count
        return this.images.length > 4 ? this.images.length - 4 : 0;
      },
      gridClass() {
        const imageCount = this.displayedImages.length;
        if (imageCount === 2) return 'image-grid image-grid-2';
        if (imageCount === 3) return 'image-grid image-grid-3';
        if (imageCount === 4) return 'image-grid image-grid-4';
        return 'image-grid';
      },
    },
  };
</script>

<style scoped>
  .image-grid-wrapper {
    position: relative;
  }
  .image-grid {
    display: grid;
    gap: 2px;
  }
  .image-grid img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  /* Custom layouts for different number of images */
  .image-grid-2 {
    grid-template-columns: 1fr 1fr;
  }
  .image-grid-3 {
    grid-template-columns: 1fr 1fr;
    /* grid-template-rows: repeat(2, 1fr); */
  }
  .image-grid-3 img:nth-child(1) {
    grid-column: span 2;
  }
  .image-grid-4 {
    grid-template-columns: repeat(2, 1fr);
  }

  /* Overlay for the remaining images */
  .more-overlay {
    position: absolute;
    bottom: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    font-size: 2rem;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    grid-column: span 2;
  }
</style>
